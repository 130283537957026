import { booksContext } from "./../../context/books-provider/BooksProvider";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import debounce from "lodash.debounce";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { userContext } from "../../context/user-provider/UserProvider";
import { sessionContext } from "../../context/session-provider/SessionProvider";
import { cartContext } from "../../context/cart-provider/CartProvider";
import { collectionsContext } from "../../context/collections-provider/CollectionsProvider";
import { ActionMeta } from "react-select";
import { currencyContext } from "../../context/currency-provider/CurrencyProvider";
import { CURRENCY } from "../../context/currency-provider/CurrencyProvider.consts";

export function useHeaderForm() {
  const { getCurrency, setCurrentCurrency } = useContext(currencyContext);
  const { error } = useContext(errorContext);
  const { getBooksByNameFilter } = useContext(booksContext);
  const { getCollectionsByNameFilter } = useContext(collectionsContext);

  const [bookTitle, setBookTitle] = useState<string>("");
  const [currencyData, setCurrencyData] = useState<
    { label: string; value: string }[]
  >([]);

  const debouncedFilteredProducts = useCallback(
    debounce(async (value: string) => {
      try {
        await getBooksByNameFilter(value);
        await getCollectionsByNameFilter(value);
      } catch (e) {
        error(e);
      }
    }, 700),
    []
  );

  const handleChangeHeaderData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setBookTitle(value);

      debouncedFilteredProducts(value);
    },
    []
  );

  const handleChangeSelectData = useCallback((newValue: unknown) => {
    const option = newValue as { label: string; value: string };

    setCurrencyData([option]);
    setCurrentCurrency(option.value);

    window.location.reload();
  }, []);

  const clearFilteredProducts = async () => {
    try {
      await getBooksByNameFilter("");
      await getCollectionsByNameFilter("");

      setBookTitle("");
    } catch (e) {
      error(e);
    }
  };

  const currencyOptions = useMemo(
    () => [
      { label: "EUR", value: "EUR" },
      { label: "USD", value: "USD" },
      { label: "GBP", value: "GBP" },
      { label: "UAH", value: "UAH" },
      { label: "AUD", value: "AUD" },
    ],
    []
  );

  useEffect(() => {
    const currency = getCurrency();

    setCurrencyData([{ label: currency, value: currency }]);
  }, []);

  return {
    bookTitle,
    currencyData,
    currencyOptions,
    handleChangeSelectData,
    handleChangeHeaderData,
    clearFilteredProducts,
  };
}

export function useHeaderFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);
  const { getRoleFromToken } = useContext(sessionContext);
  const { getCartProducts, cartCount } = useContext(cartContext);

  const role = getRoleFromToken();

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const [isOpenPreviewCart, setIsOpenPreviewCart] = useState(false);
  const [isOpenCartModal, setIsOpenCartModal] = useState(false);

  const handleOpenCartModal = useCallback(() => {
    setIsOpenCartModal(true);
    setIsOpenPreviewCart(false);
  }, [setIsOpenCartModal]);

  const profileFetch = async () => {
    try {
      if (role) {
        setIsAuthLoading(true);

        await getProfile();
      }
    } catch (e) {
      error(e);
    } finally {
      setIsAuthLoading(false);
    }
  };

  useEffect(() => {
    profileFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCartProducts();
  }, [isOpenPreviewCart, isOpenCartModal, cartCount]);

  return {
    isAuthLoading,
    isOpenPreviewCart,
    isOpenCartModal,
    cartCount,
    handleOpenCartModal,
    setIsOpenPreviewCart,
    setIsOpenCartModal,
  };
}
